/** ClientsReviewSection **/
.ClientsReviewSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  gap: 28px;
}

.ClientsReviewSection .Slider {
  display: flex;
  width: 100%;
  /*overflow: hidden;*/
  gap: 24px;
}

.ClientsReviewSection .Slider .ReviewPaper {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 40px;
  width: 614px;
  height: 302px;
  gap: 24px;
  background: var(--color-orange);
}

.ClientsReviewSection .Slider .ReviewPaper .Avatar {
  position: relative;
}

.ClientsReviewSection .Slider .ReviewPaper .Avatar img {
  width: 118px;
  height: 118px;
  flex-shrink: 0;
  border-radius: 86px;
  /*border: 1px solid #191A2C;*/
  /*box-shadow: -5px 4px 0px 0px #191A2C;*/
}

.ClientsReviewSection .Slider .ReviewPaper .Avatar object {
  position: absolute;
  bottom: -36px;
  left: -36px;
}

.ClientsReviewSection .Slider .ReviewPaper .CommentInfo {
  display: flex;
  flex-direction: column;
}

.ClientsReviewSection .Slider .ReviewPaper hr {
  border-top: 1px solid var(--color-dark);
  margin: 14px 0 20px 0;
}

.ClientsReviewSection .Slider .ReviewPaper.is-active {
  background: var(--color-dark);
}

.ClientsReviewSection .Slider .ReviewPaper.is-active span  {
  color: var(--color-white);
}

.ClientsReviewSection .Slider .ReviewPaper.is-active hr  {
  border-top: 1px solid var(--color-white);
}

.ClientsReviewSection .SliderControl {
  display: flex;
  flex-direction: row;
  gap: 32px;
  display: none;
}

.ClientsReviewSection .SliderControl img {
  cursor: pointer;
}

#ReviewsSplide {
  width: 100%;
  padding: 0 16px;
}


@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1400px) {

}


@media screen and (max-width: 1280px) {

}


@media screen and (max-width: 743px) {
  .ClientsReviewSection .Slider .ReviewPaper {
    width: 100%;
    height: auto;
    padding: 20px 12px;
    gap: 12px;
  }

  .ClientsReviewSection .Slider .ReviewPaper .Avatar img {
    width: 60px;
    height: 60px;
  }

  .ClientsReviewSection .Slider .ReviewPaper .Avatar object {
    display: none;
  }

  .ClientsReviewSection .Slider {
    flex-direction: row;
    gap: 0;
    padding: 0 20px;
  }
}
