/* background variants */
.BackgroundColorBlack {
  background: var(--color-dark);
}

.BackgroundColorOrange {
  background: var(--color-orange);
}

.BackgroundColorWhite {
  background: var(--color-white);
}

.BackgroundColorSilver {
  background: var(--color-silver);
}

a {
  text-decoration: none;
}

form {
  display: flex;
  width: 544px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  flex-shrink: 0;
}

input, textarea {
  display: flex;
  width: 100%;
  padding: 13px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--color-dark);
  background: var(--color-white);
}

input,
textarea,
input::placeholder,
textarea::placeholder {
  color: var(--color-dark);
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input {
  height: 52px;
}

textarea {
  resize: none;
  height: 200px;
}

.AppButton {
  display: flex;
  width: 255px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 16px 24px !important;
  margin-bottom: 5px !important;
  margin-left: 5px !important;
  transition: all 100ms linear;
}

.AppButton:disabled,
.AppButton:active {
  box-shadow: none !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.AppButton:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.Logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}


header {
  display: flex;
  width: 100vw;
  height: 100px;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  position: fixed;
  z-index: 100;
}

header nav {
  display: flex;
  width: 100%;
  max-width: 1680px;
  height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

header .FloatingMenuBlock {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -1038px;
  width: 100%;
  max-width: 972px;
  height: auto;
  padding: 160px 0 200px;
  transition: top 100ms;
}

header .FloatingMenuBlock.Active {
  top: 100%;
}


header .FloatingMenuBlock .FloatingMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 44px;
}

header .FloatingMenu a {
  border-bottom: 3px solid var(--color-dark);
}

header .FloatingMenu a:hover {
  border-bottom: 3px solid var(--color-orange);
}

.MenuBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 8px;
}

.Sandwich {
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1.5px solid var(--color-orange);
  cursor: pointer;
}

.Sandwich:hover {
  opacity: 0.9;
}

.SandwichIcon {
  display: flex;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.SandwichIcon > span,
.SandwichIcon > span::before,
.SandwichIcon > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--color-orange);
  transition-duration: .25s;
  border-radius: 1px;
}

.SandwichIcon > span::before {
  content: '';
  top: -8px;
}

.SandwichIcon > span::after {
  content: '';
  top: 8px;
}

.Sandwich.Active .SandwichIcon > span {
  transform: rotate(45deg);
}

.Sandwich.Active .SandwichIcon > span::before {
  top: 0;
  transform: rotate(0);
}

.Sandwich.Active .SandwichIcon > span::after {
  top: 0;
  transform: rotate(90deg);
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0;
  min-height: calc(100vh - 100px);
  position: relative;
}

.Section {
  width: 100%;
  max-width: 1680px;
}

footer {
  display: flex;
  width: 100%;
  max-width: 1680px;
  padding: 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin: 60px auto 0;
}

footer nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
}

footer nav .Menu {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.splide__pagination {
  bottom: -24px;
  gap: 16px;
}

.splide__pagination__page {
  border: 2px solid var(--color-orange);
  background: none;
  opacity: 1;
}

.splide__pagination__page.is-active {
  background: var(--color-orange);
}


@media screen and (max-width: 1780px) {
  main {
    padding: 100px 32px 0;
  }
}

@media screen and (max-width: 1280px) {
  .AppButton {
    display: flex;
    width: 204px;
    height: 48px;
  }

  header {
    padding: 0 16px;
  }

  main {
    padding: 100px 16px 0;
  }

  header .FloatingMenuBlock {
    position: fixed;
    top: -100vh;
    width: 100vw;
    height: calc(100vh - 100px);
    padding: 60px 0 0;
  }

  header .FloatingMenuBlock.Active {
    top: 100px;
  }
}

@media screen and (max-width: 1168px) {
  footer {
    padding: 0;
  }

  footer nav {
    flex-direction: column;
    padding: 20px;
    gap: 40px;
  }

  footer nav .Menu {
    flex-direction: column;
    align-items: center;
    gap: 28px;
  }

  footer nav .Copyright {
    margin-top: 20px;
  }
}


@media screen and (max-width: 960px) {
  header nav .MenuBlock>span {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .AppButton {
    display: flex;
    width: 100%;
    height: 48px;
  }

  header {
    height: 86px;
  }

  header nav {
    height: 86px;
  }

  header .FloatingMenuBlock.Active {
    top: 86px;
  }

  header .FloatingMenuBlock {
    height: calc(100vh - 86px);
  }

  main {
    padding: 100px 0 0;
    gap: 8px;
  }
}




