
/** OurServicesSection **/
.WhyWeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.WhyWeSection h3 {
  max-width: 800px;
  text-align: center;
  padding-top: 4px;
}

.WhyWeSection .Content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  gap: 24px;
}

.WhyWeSection .Content .PlanBlock {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.WhyWeSection .Content .PlanBlock .Image {
  display: none;
}

.WhyWeSection .Content .PlanBlock .PlanItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  position: relative;
  padding-left: 44px;
  gap: 12px;
  cursor: pointer;
  /*transition: all 300ms linear;*/
}

.WhyWeSection .Content .PlanBlock .PlanItem .Before,
.WhyWeSection .Content .PlanBlock .PlanItem .After {
  display: flex;
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
}
.WhyWeSection .Content .PlanBlock .PlanItem .Before {
  border: 1px solid var(--color-orange);
  top: 0;
  left: -24px;
}
.WhyWeSection .Content .PlanBlock .PlanItem .After {
  display: none;
  bottom: -24px;
  right: -24px;
}

/*.WhyWeSection .Content .PlanBlock .PlanItem:hover,*/
.WhyWeSection .Content .PlanBlock .PlanItem.Active {
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  padding-bottom: 40px;
  border-left: 1px solid var(--color-dark);
  border-bottom: 1px solid var(--color-dark);
  border-bottom-left-radius: 14px;
}

.WhyWeSection .Content .PlanBlock .PlanItem .Subtitle {
  display: none;
}

/*.WhyWeSection .Content .PlanBlock .PlanItem:hover .Before,*/
/*.WhyWeSection .Content .PlanBlock .PlanItem:hover .After,*/
.WhyWeSection .Content .PlanBlock .PlanItem.Active .Before,
.WhyWeSection .Content .PlanBlock .PlanItem.Active .After {
  display: flex;
  color: var(--color-white);
  background: var(--color-orange);
}

/*.WhyWeSection .Content .PlanBlock .PlanItem:hover .Subtitle,*/
.WhyWeSection .Content .PlanBlock .PlanItem.Active .Subtitle {
  display: inline;
}

.WhyWeSection .Content .Image {
  flex-shrink: 0;
}

.WhyWeSection .Content .PlanBlock .Image img,
.WhyWeSection .Content .Image img
{
  width: 100%;
  max-width: 686px;
}

@media screen and (max-width: 1368px) {
  .WhyWeSection h3 {
    padding: 4px 5% 0;
  }

  .WhyWeSection .Content {
    gap: 16px;
  }

  .WhyWeSection .Content .Image {
    width: 41%;
  }

  .WhyWeSection .Content .PlanBlock {
    gap: 20px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem {
    height: 32px;
    padding-left: 24px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem.Active {
    padding-bottom: 16px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem .Before,
  .WhyWeSection .Content .PlanBlock .PlanItem .After {
    width: 32px;
    height: 32px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem .Before {
    left: -16px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem .After {
    bottom: -16px;
    right: -16px;
  }
}

@media screen and (max-width: 743px) {
  .WhyWeSection {
    padding: 0 16px;
  }

  .WhyWeSection .Content {
    flex-direction: column-reverse;
  }

  .WhyWeSection .Content .Image {
    width: 100%;
  }

  .WhyWeSection .Content .PlanBlock {
    gap: 16px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem {
    height: 32px;
    padding-left: 24px;
    gap: 12px;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem.Active {
    border: none;
  }

  .WhyWeSection .Content .PlanBlock .PlanItem.Active .After {
    display: none;
  }
}
