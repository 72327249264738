/** OurProductSection **/
.OurProductSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.OurProductSection h2{
  width: 100%;
}

.OurProductSection .ProductSlider {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.OurProductSection .Content {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.OurProductSection .Content:nth-child(2) {
  padding-top: 10px;
}

.OurProductSection .Content:nth-child(2) .Text span:nth-child(2) {
  width: 85%;
}

.OurProductSection .Content:nth-child(3) {
  padding-top: 64px;
}

.OurProductSection .Content .Text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.OurProductSection .Content .Image {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 40%;
  flex-shrink: 0;
  gap: 24px;
}

.OurProductSection .Content .Image img {
  width: 100%;
  max-width: 680px;
}

.OurProductSection .Content .Image.LaptopPhoneGroup {
  width: 50%;
}

.OurProductSection .Content .Image img.LaptopImg {
  width: 100%;
  max-width: 804px;
}

.OurProductSection .TransitionImage {
  width: 100%;
  padding: 0 10%;
  text-align: right;
}

.OurProductSection .TransitionImage img {
  width: 100%;
  max-width: 540px;
}

#ProductsSplide {
  width: 100%;
}


@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1368px) {
  .OurProductSection .ProductSlider {
    gap: 40px;
  }
  .OurProductSection .Content .Image {
    width: calc(50% - 8px);
  }

  .OurProductSection .Content {
    gap: 16px;
  }

  .OurProductSection .Content:nth-child(2) {
    padding-top: 20px;
  }

  .OurProductSection .Content:nth-child(2) .Text span:nth-child(2) {
    width: 100%;
  }

  .OurProductSection .TransitionImage {
    padding-left: 30%;
  }

  .OurProductSection .TransitionImage img {
    width: 50%;
  }
}

@media screen and (max-width: 890px) {
  .OurProductSection .TransitionImage {
    padding-left: 10%;
    padding-right: 25%;
  }
}


@media screen and (max-width: 743px) {
  .OurProductSection {
    padding: 0 16px;
  }

  .OurProductSection h2 {
    text-align: center;
  }

  .OurProductSection .ProductSlider {
    flex-direction: row;
    gap: 0;
  }

  .OurProductSection .Content {
    flex-direction: column;
    padding: 20px 16px;
    border-radius: 2px;
    border: 1px solid var(--color-dark);
    background: var(--color-white);
    box-shadow: 0 20px 16px 0 rgba(25, 26, 44, 0.10);
    gap: 12px;
  }

  .OurProductSection .Content:nth-child(2) .Text span:nth-child(2) {
     width: 100%;
  }

  .OurProductSection .Content:nth-child(3) {
    padding: 20px 16px;
  }

  .OurProductSection .Content .Image,
  .OurProductSection .Content .Image.LaptopPhoneGroup {
    width: 100%;
  }

  .OurProductSection .TransitionImage {
    width: 100%;
    padding: 0 10%;
    text-align: left;
    margin-top: 10px;
  }

  .OurProductSection .TransitionImage img {
    width: 50%;
  }
}
