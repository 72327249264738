.HomeSection {
  display: flex;
  flex-direction: column;
  padding-top: 36px;
}

.HomeSection .Content {
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.HomeSection .Content .TextPaper {
  display: flex;
  width: 58%;
  flex-direction: column;
  padding-top: 40px;
  gap: 4px;
}

.HomeSection .Content .TextPaper span {
  max-width: 80%;
}

.HomeSection .Content .TextPaper .ButtonBlock {
  padding-top: 24px;
}

.HomeSection .Content .TextPaper .ButtonBlock button {
  background: var(--color-orange);
  box-shadow: -5px 4px 0 0 var(--color-dark);
}


.HomeSection .Content .Image {
  width: 35%;
  max-width: 600px;
  flex-shrink: 0;
}

.HomeSection .Content .Image img {
  width: 100%;
  max-width: 600px;
  min-width: 300px;
}

.HomeSection .TransitionImage {
  width: 100%;
  text-align: right;
  padding: 0 19%;
}

.HomeSection .TransitionImage img {
  width: 60%;
  max-width: 620px;
}


@media screen and (max-width: 1268px) {
  .HomeSection .Content .Image {
    width: 45%;
  }
}


@media screen and (max-width: 968px) {
  .HomeSection .Content {
    gap: 20px;
  }

  .HomeSection .Content .TextPaper {
    padding-top: 0;
    width: 50%;
  }

  .HomeSection .Content .TextPaper span {
    max-width: 100%;
  }

  .HomeSection .Content .Image {
    width: 50%;
  }
}


@media screen and (max-width: 743px) {
  .HomeSection {
    padding: 24px 16px 0;
    gap: 8px;
  }

  .HomeSection .Content {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .HomeSection .Content .TextPaper {
    padding-top: 0;
    width: 100%;
  }

  .HomeSection .Content .Image {
    width: 100%;
    max-width: 743px;
  }

  .HomeSection .Content .Image img {
    max-width: 743px;
  }

  .HomeSection .TransitionImage {
    text-align: center;
    padding-left: 0;
  }

  .HomeSection .TransitionImage img {
    width: 35%;
    margin-right: 25%;
  }
}
