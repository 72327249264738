
/** OurServicesSection **/
.OurServicesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.OurServicesSection h2 {
  width: 100%;
  text-align: right;
}

.OurServicesSection .CardList {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-top: 4px;
}

.OurServicesSection .CardList .CardItem {
  width: 100%;
  height: 390px;
  transition: padding-top 300ms;
}

.OurServicesSection .CardList .CardItem:hover {
  padding-top: 40px;
}

.OurServicesSection .CardList .CardItem .CardItemPaper {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 0;
}

.OurServicesSection .CardList .CardItem .CardItemPaper img {
  width: 154px;
  height: 154px;
}

.OurServicesSection .CardList .CardItem:hover .CardItemPaper {
  border-radius: 2px;
  background: #FFF;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.10);
}

.OurServicesSection .CardList .CardItem span {
  text-align: center;
}

.OurServicesSection .SoftwareSolutionsBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  padding: 40px 0;
}

.OurServicesSection .SoftwareSolutionsBlock .SolutionList {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.OurServicesSection .SoftwareSolutionsBlock .SolutionList .SolutionItem {
  width: 100%;
  height: 304px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
  gap: 8px;
}

.OurServicesSection .SoftwareSolutionsBlock .SolutionList .SolutionItem img {
  width: 110px;
  height: 110px;
}

.OurServicesSection .SoftwareSolutionsBlock .SolutionList span {
  text-align: center;
}

.OurServicesSection .TransitionImage {
  width: 100%;
  text-align: center;
  padding: 0 10px;
}

.OurServicesSection .TransitionImage img {
  width: 100%;
  max-width: 620px;
}


@media screen and (max-width: 1368px) {
  .OurServicesSection .CardList .CardItem {
    height: 225px;
  }

  .OurServicesSection .CardList .CardItem .CardItemPaper {
    height: 185px;
    gap: 24px;
    padding: 20px 0;
  }

  .OurServicesSection .CardList .CardItem .CardItemPaper img {
    width: 80px;
    height: 80px;
  }

  .OurServicesSection .SoftwareSolutionsBlock {
    padding: 20px 16px;
  }

  .OurServicesSection .SoftwareSolutionsBlock .SolutionList {
    gap: 20px;
  }

  .OurServicesSection .SoftwareSolutionsBlock .SolutionList .SolutionItem {
    height: auto;
    padding: 20px 0;
  }

  .OurServicesSection .SoftwareSolutionsBlock .SolutionList .SolutionItem img {
    width: 80px;
    height: 80px;
  }

  .OurServicesSection .TransitionImage img {
    width: 40%;
  }
}


@media screen and (max-width: 743px) {
  .OurServicesSection h2 {
    text-align: center;
  }

  .OurServicesSection .CardList {
    flex-wrap: wrap;
    justify-content: center;
  }

  .OurServicesSection .CardList .CardItem {
    width: 40%;
    height: auto;
  }

  .OurServicesSection .CardList .CardItem .CardItemPaper {
    height: auto;
    padding: 0;
  }

  .OurServicesSection .SoftwareSolutionsBlock .SolutionList {
    flex-wrap: wrap;
    justify-content: center;
  }

  .OurServicesSection .SoftwareSolutionsBlock .SolutionList .SolutionItem {
    width: 40%;
    height: auto;
    padding: 16px;
  }

  .OurServicesSection .TransitionImage {
    width: 100%;
    padding: 0 10%;
  }

  .OurServicesSection .TransitionImage img {
    width: 50%;
  }
}
