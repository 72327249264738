/** ContactUsSection **/
.ContactUsSection {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 28px;
  padding: 40px;
}

.ContactUsSection h3 {
  width: 60%;
}

.ContactUsSection .Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 36px;
}

.ContactUsSection .Content .LeftColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContactUsSection .Content .RightColumn {
  padding-top: 67px;
}

.ContactUsSection .Content .RightColumn img {
  width: 100%;
  max-width: 577px;
}

.ContactUsSection .ContactInfoBlock {
  display: flex;
  flex-direction: row;
  gap: 132px;
}

.ContactUsSection .ContactInfoBlock .ContactData {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContactUsSection .ContactInfoBlock .ContactItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ContactUsSection button {
  background: #C0C0C0;
  box-shadow: -5px 4px 0 0 var(--color-white);
}




@media screen and (max-width: 1368px) {

}

@media screen and (max-width: 1280px) {
  .ContactUsSection .ContactInfoBlock {
    gap: 24px;
  }
}

@media screen and (max-width: 1160px) {
  .ContactUsSection {
    margin-top: 60px;
    align-items: center;
    padding: 20px 98px;
  }

  .ContactUsSection h3 {
    width: 100%;
  }

  .ContactUsSection .Content {
    width: 100%;
  }

  .ContactUsSection .Content .LeftColumn {
    width: 100%;
  }

  .ContactUsSection .Content form {
    width: 100%;
    max-width: 960px;
  }

  .ContactUsSection  .Content form button {
    width: calc(100% - 5px);
  }

  .ContactUsSection .Content .RightColumn {
    display: none;
  }

  .ContactUsSection .ContactInfoBlock {
    flex-direction: column-reverse;
    gap: 16px;
  }


  .ContactUsSection .ContactInfoBlock .ContactData {
    flex-direction: row;
  }
}


@media screen and (max-width: 743px) {
  .ContactUsSection {
    padding: 20px 16px;
  }

  .ContactUsSection h3{
    width: 100%;
  }

  .ContactUsSection .Content .LeftColumn {
    width: 100%;
  }

  .ContactUsSection .Content .LeftColumn form {
    width: 100%;
  }

  .ContactUsSection .Content .RightColumn {
    display: none;
  }

  .ContactUsSection .ContactInfoBlock .ContactData {
    flex-direction: column;
  }
}
