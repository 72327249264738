/** AboutSection **/
.AboutSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.AboutSection .Content {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.AboutSection .Content .Image {
  flex-shrink: 0;
}

.AboutSection .Content .Image img {
  width: 100%;
  max-width: 600px;
  min-width: 340px;
}

.AboutSection .Content .Text {
  max-width: 50%;
}

.AboutSection  .MetricBlock {
  width: 100%;
  padding-top: 72px;
}

.AboutSection  .MetricBlock .MetricPaper {
  display: flex;
  flex-direction: row;
  padding: 80px 40px;
  gap: 68px;
}

.AboutSection .MetricBlock .MetricPaper .MetricTitle {
  width: 100%;
}

.AboutSection  .MetricBlock .MetricPaper .MetricItemList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
  gap: 68px;
}

.AboutSection .MetricBlock .MetricPaper .MetricItem {
  width: 100%;
  max-width: 274px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  flex-shrink: 0;
}

.AboutSection .TransitionImage {
  width: 100%;
  padding-left: 6%;
}

.AboutSection .TransitionImage img {
  width: 100%;
  max-width: 510px;
}


@media screen and (max-width: 1368px) {
  .AboutSection {
    gap: 16px;
  }

  .AboutSection .Content {
    gap: 20px;
  }


  .AboutSection .Content .Image {
    width: 50%;
  }

  .AboutSection .MetricBlock {
    padding-top: 24px;
  }

  .AboutSection  .MetricBlock .MetricPaper {
    flex-direction: column;
    padding: 40px 20px;
    gap: 32px;
  }

  .AboutSection  .MetricBlock .MetricPaper {

  }

  .AboutSection .MetricBlock .MetricPaper .MetricTitle {
    text-align: center;
  }

  .AboutSection .MetricBlock .MetricPaper .MetricItem {
    flex-shrink: 1;
  }

  .AboutSection .TransitionImage {
    padding-left: 5%;
    padding-right: 30%;
  }

  .AboutSection .TransitionImage img{
    width: 50%;
  }
}


@media screen and (max-width: 743px) {
  .AboutSection .Content {
    flex-direction: column;
    gap: 20px;
    padding: 0 16px;
  }

  .AboutSection .Content .Image {
    width: 100%;
    max-width: 743px;
  }

  .AboutSection .Content .Image img {
    max-width: 743px;
  }

  .AboutSection .Content .Text {
    max-width: 100%;
  }

  .AboutSection  .MetricBlock {
    width: 100%;
    padding-top: 16px;
  }

  .AboutSection .MetricBlock .MetricPaper {
    padding: 20px 16px;
    gap: 20px;
  }

  .AboutSection .MetricBlock .MetricPaper .MetricItemList {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .AboutSection .TransitionImage {
    width: 100%;
    padding: 0 10%;
  }

  .AboutSection .TransitionImage img {
    width: 50%;
  }
}
