@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&family=Syncopate:wght@400;700&display=swap');

html {
  font-size: 16px;
}

/* text color variants */
.ColorBlack {
  color: var(--color-black);
}

.ColorDark {
  color: var(--color-dark);
}

.ColorSilver {
  color: var(--color-grey);
}

.ColorOrange {
  color: var(--color-orange);
}

.ColorWhite {
  color: var(--color-white);
}

/* text variants Title */
.TypographyTitle1,
.TypographyTitle12,
.TypographyTitle2,
.TypographyTitle3,
.TypographyTitle4,
.TypographyTitle5,
.TypographyTitle6,
.TypographyTitle7 {
  font-family: "Syncopate";
  font-style: normal;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

.TypographyTitle1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 94px;
}

.TypographyTitle12 {
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
}

.TypographyTitle2 {
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
}

.TypographyTitle3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
}

.TypographyTitle4 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.TypographyTitle5 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.TypographyTitle6 {
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.TypographyTitle7 {
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
}

/* text variants Subtitle */
.TypographySubtitle11,
.TypographySubtitle12,
.TypographySubtitle21,
.TypographySubtitle22,
.TypographySubtitle23,
.TypographySubtitle24,
.TypographyBody11 ,
.TypographyBody12 ,
.TypographyBody21 ,
.TypographyBody22 ,
.TypographyBody31 ,
.TypographyBody32 ,
.TypographyBody41 ,
.TypographyBody51 ,
.TypographyBody61 ,
.TypographyBody62 ,
.TypographyBody71 {
  font-family: "Roboto Mono";
  font-style: normal;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

.TypographySubtitle11 {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
}

.TypographySubtitle12 {
  font-size: 40px;
  font-weight: 500;
  line-height: 64px;
}

.TypographySubtitle21 {
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
}

.TypographySubtitle22 {
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
}

.TypographySubtitle23 {
  font-size: 36px;
  font-weight: 500;
  line-height: 52px;
}

.TypographySubtitle24 {
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
}

/* text variants Body */
.TypographyBody11 {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}

.TypographyBody12 {
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
}

.TypographyBody21 {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px
}

.TypographyBody22 {
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
}

.TypographyBody31 {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.TypographyBody32 {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}

.TypographyBody41 {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.TypographyBody51 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.TypographyBody61 {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.TypographyBody62 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.TypographyBody71 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1649px) {
  .TypographyTitle1 {
    font-size: 48px;
    line-height: 64px;
  }

  .TypographyTitle12 {
    font-size: 56px;
  }

  .TypographyTitle2 {
    font-size: 44px;
    line-height: 56px;
  }

  .TypographyTitle3 {
    font-size: 32px;
    line-height: 40px;
  }

  .TypographyTitle4 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographyTitle6 {
    font-size: 10px;
  }




  .TypographySubtitle11 {
    font-size: 24px;
    line-height: 32px;
  }

  .TypographySubtitle12 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle21 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle22 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle23 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle24 {
    font-size: 20px;
  }

  /* text variants Body */
  .TypographyBody11 {
    font-size: 14px;
    line-height: 20px;
  }

  .TypographyBody12 {
    font-size: 16px;
  }

  .TypographyBody21 {
    font-size: 14px;
    line-height: 20px
  }

  .TypographyBody22 {
    font-size: 20px;
    line-height: 22px;
  }

  .TypographyBody31 {
    font-size: 16px;
    line-height: normal;
  }

  .TypographyBody32 {
    font-size: 16px;
    line-height: 20px;
  }

  .TypographyBody41 {
    font-size: 16px;
    line-height: 20px;
  }

  .TypographyBody51 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .TypographyBody61 {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  .TypographyBody71 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}


@media screen and (max-width: 1268px) {
  .TypographyTitle1 {
    font-size: 24px;
    line-height: 32px;
  }

  .TypographyTitle12 {
    font-size: 40px;
    line-height: 44px;
  }

  .TypographyTitle2 {
    font-size: 24px;
    line-height: 32px;
  }

  .TypographyTitle3 {
    font-size: 24px;
    line-height: 32px;
  }



  .TypographySubtitle11 {
    font-size: 24px;
    line-height: 32px;
  }

  .TypographySubtitle12 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle21 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle22 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle23 {
    font-size: 20px;
    line-height: 24px;
  }

  .TypographySubtitle24 {
    font-size: 20px;
  }

  /* text variants Body */
  .TypographyBody11 {
    font-size: 14px;
    line-height: 20px;
  }

  .TypographyBody12 {
    font-size: 16px;
  }

  .TypographyBody21 {
    font-size: 14px;
    line-height: 20px
  }

  .TypographyBody22 {
    font-size: 20px;
    line-height: 22px;
  }

  .TypographyBody31 {
    font-size: 16px;
    line-height: normal;
  }

  .TypographyBody32 {
    font-size: 16px;
    line-height: 20px;
  }

  .TypographyBody41 {
    font-size: 16px;
    line-height: 20px;
  }

  .TypographyBody51 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .TypographyBody61 {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  .TypographyBody71 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}
